.sl-wrapper {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: rgb(255, 255, 255, 0.95);
}

.sl-image {
  max-width: calc(100vw - 100px);
  margin: 0 auto;
  align-self: center;
}

.sl-image img {
  object-fit: cover;
  height: 100%;
}

.sl-close, .sl-prev, .sl-next {
  background: none;
  position: absolute;
}


.sl-prev, .sl-next {
  color: rgb(255, 255, 255, 0);
  top: 50%;
  width: 34px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: 24px 12px;
  background-position: 5px 5px;
}

.sl-prev:hover, .sl-next:hover {
  cursor: pointer;
  opacity: 0.5;
}

.sl-prev {
  left: 6px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+R3JvdXAgMjwvdGl0bGU+PGcgc3Ryb2tlPSIjNEM0QzRDIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+PHBhdGggZD0iTTIyIDEwbC00LTQgNC00TTE0IDEwbC00LTQgNC00TTYgMTBMMiA2bDQtNCIvPjwvZz48L3N2Zz4=');
}

.sl-next {
  right: 6px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojNEM0QzRDO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cjwvc3R5bGU+Cjx0aXRsZT5Hcm91cCAyPC90aXRsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMiwybDQsNGwtNCw0IE0xMCwybDQsNGwtNCw0IE0xOCwybDQsNGwtNCw0Ii8+CjwvZz4KPC9zdmc+Cg==');
}

.sl-close {
  right: 0;
  top: 0;
  font-size: 2em;
  width: 1.66667em;
  height: 1.66667em;
  line-height: 1.66667em;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.sl-close:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}