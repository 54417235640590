@charset "UTF-8";
@import url("https://use.typekit.net/ghj3tkh.css");
@import url("simpleLightbox.css");
@import url("hamburgers.css");
@import url("swiper.min.css");

/* =====  1 Global Rules & Definitions  ===================================== */

/* -----  1.1 Font Definitions  --------------------------------------------- */


/* -----  1.2 Reset & Normalization  ---------------------------------------- */

*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  list-style: none;
}

article, aside, details, figcaption, figure,
footer, header, main, menu, nav, section, summary {
  /* Add correct display for IE 9- and some newer browsers */
  display: block;
}

::-moz-selection {
  background: #4C4C4C;
  color: #FFFFFF;
}

::selection {
  background: #4C4C4C;
  color: #FFFFFF;
}

/* -----  1.3 General Typography  ------------------------------------------- */

h1, h2, h3, h4, h5, h6,
p, ul, ol, figure, pre {
  /* Reset font-sizes and normalize margins */
  font-size: inherit;
  line-height: inherit;
  margin: 0 0 0.75rem;
  hyphens: auto;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  font-size: 15px;
}



/* Headings */
h1, h2, h3, .h1, .h2, .h3, em, i {
  font-family: "corporate-s", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  font-style: normal;
  margin: 0;
  letter-spacing: 0.15em;
}

p + h1, p + h2, p + h3, 
p + .h1, p + .h2, p + .h3 {
  margin: 1.5rem 0 0 0;
}

/* Links */
a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid #4C4C4C;
}

a:hover, a:focus, a:active {
  opacity: 0.5;
  transition: opacity 0.25s;
}

/* Misc */
/*i, em {
  font-weight: 500;
  font-style: normal;
}*/

b, strong {
  font-weight: 600;
}

mark {
  display: inline-block;
  color: #4C4C4C;
  background: none;
  font-family: "corporate-s", Helvetica, Arial, sans-serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1rem;
}

main.main-slide .content li {
  list-style-type: square;
  list-style-position: outside;
  margin-left: 1rem;

}

/* -----  1.4 Images & Figures  --------------------------------------------- */

img {
  border-style: none;
  width: 100%;
  height: auto;
}

img[data-sizes="auto"] { display: block; }

figcaption {
  font-size: .75rem;
  line-height: 1.5rem;
}

/* =====  2 Layout Helpers & Reusable Components  =========================== */

/* The Clearfix™: A classic … */
.cf::after {
  content: "";
  display: block;
  height: 0;
  overflow: hidden;
  clear: both;
}

/* -----  2.1 Kirbytext  ---------------------------------------------------- */

.text h2, .text h3 {
  margin-top: 3rem;
}

.text ul, .text ol {
  margin-left: 1.5em;
}

/* -----  2.2 Button  ------------------------------------------------------- */

.btn {
  display: inline-block;
  border: 2px solid;
  padding: .6em .75em;
  white-space: nowrap;
  font-size: .75em;
  font-family: "corporate-a", Georgia, Times, serif;
  line-height: 1;
  font-weight: 400;
}

.btn:hover, .btn:focus {
  background: #4C4C4C;
  border-color: #4C4C4C;
  color: #71efac;
}

.btn:active {
  background: #4509be;
  border-color: #4509be;
}

/* -----  2.3 Grid & Layout  ------------------------------------------------ */




.grid {
  font-size: 0;
}

.column {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  vertical-align: top;
}

.header-slide {
  margin: 0 1.5rem;
  background-color: #ccc;
  height: 75vh;
}

.main-slide {
  display: flex;
  flex-grow: 1;
  padding: 4rem 1rem 1rem 1rem;
}

.main-slide .content {
  background-color: rgba(255,255,255,0.95);
  padding: 0.75rem 1rem;
  width: 50%;
  transition: width 0.3s ease-out;
}

.main-slide.contact .content {
  width: 25%;
  margin-left: 75%;
}


.main-slide .content p:last-child {
  margin: 0;
}

header .header-logo {
  width: 150px;
}


@media all and (min-width: 31.5em) {

  .wrap:not(.wide) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

}



/* =====  3 Site Layout  ==================================================== */

html {
  background: #ffffff;
  color: #4C4C4C;
  font: italic 300 1em/1.57 "corporate-a", Georgia, Times, serif;
  letter-spacing: 0.0625rem;
  /* line-height: 1.2;*/
  /* Always show the vertical scrollbar to prevent `jumps`
     of page content after navigating from a very short page
     that does not scroll to a long pager */
  overflow-y: scroll;
  /* Setting this to `default` prevents the cursors from
     changing when hovering blocks of text or whitespace,
     which is less distracting */
  cursor: default;
  /* Neccessary for sticks footer. */
  position: relative;
  min-height: 100%;
  /* Prevent adjustments of font size after orientation
     changes in IE and iOS. */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* Highlight color for WebKit-bases touch devices. */
  /*-webkit-tap-highlight-color: rgba(113, 239, 172, .5);*/
}

@media all and (max-width: 30em) {
  html {
    /* We decrease the font-size a little-bit for tiny
       screens. Because this stylesheet uses relative units
       everywhere, everything scales nicely. */
    font-size: 1em;
  }
}

body {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  padding: 5rem 0.5rem 0 0.5rem;
  max-width: 86rem;
}

header .branding {
  padding: 0.25rem 0 0 0.5rem;
  position: fixed;
  z-index: 1003;
}

header {
  display: flex;
  flex-grow: 0;
  align-items: flex-end;
  margin: 0 0 2.5rem 0;
}

main {
  flex-grow: 1;
}

main.main-slide {
  min-height: 30rem;
  background: linear-gradient(to bottom, #dddddf 0%,#cccccf 100%);
}

main.main-slide .kicker {
  text-transform: uppercase;
  font-family: "corporate-s", Helvetica, Arial, sans-serif;
  font-size: 0.8em;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1rem;
  width: 50%;
}

.cursive {
  font-family: "corporate-a", Helvetica, Arial, sans-serif;
  font-size: 28px;
  line-height: 1.15;
  font-style: italic;
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0.1em;
  margin: 0 0 0.5rem 0;
}

main.main-slide h2.cursive {
  margin-top: 5rem;
}

main.main-slide .cursive + .subline {
  text-transform: uppercase;
  font-family: "corporate-s", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  /*letter-spacing: 0.15rem;*/
  letter-spacing: 2.1px;
  margin-bottom: 1rem;
}



main.main-slide.light .kicker,
main.main-slide.light a,
main.main-slide.light a:before,
main.main-slide.light .cursive,
main.main-slide.light .subline {
  color: #FFFFFF;
  border-color: #FFFFFF;
}



footer {
  flex-grow: 0;
}

footer nav {
  display: flex;
  justify-content: flex-end;
}

/* ----- 3.1  Header  ------------------------------------------------------- */

.header {
  padding-top: 1.5rem;
}

.header-logo {
  width: 160px;
}

.branding {
  /*font-family: "corporate-s", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.95rem;*/
  padding: 0 0 0 0;
  margin-bottom: -.2rem;
}

.branding a {
  border-bottom: 0;
}

@media all and (min-width: 56em) {
  .header {
    padding-top: 3rem;
  }


/*  .branding,
  .navigation {
    vertical-align: baseline;
    width: 50%;
  }*/
}

/* -----  3.2 Menu  --------------------------------------------------------- */

  
header .hamburger {
  display: none;
}

header .branding {
  position: relative;
}

.menu {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

@media all and (max-width: 65em) {

  main.main-slide .content,
  main.main-slide .kicker {
    width: 75%;
  }

}





@media all and (max-width: 56em) {

  main.main-slide .content,
  main.main-slide .kicker {
    width: 100%;
  }


  .navigation .menu {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    max-width: none;
    top: 0;
    left: 0;
    padding-top: 7.5rem;
    padding-left: 1.5rem;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1000;
  }

  .navigation.is-active .menu {
    display: block;
  }

  .menu .menu-item {
    margin-left: 0;
    display: block;
    font-size: 1.25rem;
    line-height: 1.75;
  }

  .main-slide.contact .content {
    width: 50%;
    margin-left: 50%;
  }

  header .hamburger {
    display: block;
    position: fixed;
    top: 1rem;
    right: 0;
    margin-right: 0.5rem;
    z-index: 1003;
  }

  header {
    display: block;
    position: fixed;
    top: 0;
    padding-top: 1.5rem;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1002;
    width: 100%;
    height: 5rem;
  }

  header .header-logo {
    width: 110px;
  }

}

@media all and (max-width: 38.25em) {
  .menu {
    padding-left: 0.75rem;
  }

  .header .hamburger {
    margin-top: 0.6rem;
    margin-right: -0.2rem;
  }
}


.menu-item {
  display: inline-block;
  margin-left: 2.5rem;
  text-transform: uppercase;
  font-family: "corporate-s", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 2.1px;
}

.btn-close, .btn-back, .btn-references {
  text-transform: uppercase;
  font-family: "corporate-s", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  transition: opacity 0.3s ease-in-out;
  border: none;
  letter-spacing: 0.15em;
}

.btn-close:before {
  width: 12px;
  height: 12px;
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+Y2xvc2VfYnRuX2ljb248L3RpdGxlPjxnIHN0cm9rZT0iIzRDNEM0QyIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPjxwYXRoIGQ9Ik0yIDJsOCA4TTEwIDJsLTggOCIvPjwvZz48L3N2Zz4=');
  margin-right: 0.5rem;
  display: inline-block;
}

.btn-back:before {
  width: 24px;
  height: 12px;
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyNCAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+R3JvdXAgMjwvdGl0bGU+PGcgc3Ryb2tlPSIjNEM0QzRDIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+PHBhdGggZD0iTTIyIDEwbC00LTQgNC00TTE0IDEwbC00LTQgNC00TTYgMTBMMiA2bDQtNCIvPjwvZz48L3N2Zz4=');
  margin-right: 0.5rem;
  display: inline-block;
}

.btn-references:before {
  width: 24px;
  height: 12px;
  content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojNEM0QzRDO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnNxdWFyZTt9Cjwvc3R5bGU+Cjx0aXRsZT5Hcm91cCAyPC90aXRsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMiwybDQsNGwtNCw0IE0xMCwybDQsNGwtNCw0IE0xOCwybDQsNGwtNCw0Ii8+CjwvZz4KPC9zdmc+Cg==');
  margin-right: 0.5rem;
  display: inline-block;
}

.btn-close:hover {
  opacity: 0.5;
  cursor: pointer;
}






.team-portrait img, .project-image img {
  /* Eliminate bottom margin */
  display: block;
}

.project-image-placeholder {
  height: 0;
  padding-bottom: 3rem;
  margin: 0 0 0.75rem;
}

.team-name, .project-name {
  margin-bottom: 0;
}

.team-contact a {
  word-wrap: break-word;
}

.project-item figcaption {
  text-align: right;
  color: #9F9F9F;
  font-size: 12px;
  min-height: 1.5rem;
  margin-bottom: -1.5rem;
}

.project-text {
  margin-top: -0.4rem;
}

.team-about {
  
  max-height: 0;
  overflow: hidden;
  transition: max-height .25s ease-in-out;
}

.team-item {
  opacity: 1;
  transition: opacity 0.25s;
  cursor: pointer;
}

.team-item button {
  text-align: left;
  background: none;
}

.team-item button h3 {
  color: #4C4C4C;
}

.team-item button p {
    color: #4C4C4C;
    font-family: "corporate-a", Georgia, Times, serif;
    font-style: italic;
    line-height: 1.57;
    letter-spacing: 0.0625rem;
}

.team-item button:hover {
  transition: opacity .25s;
}

.project-list {
  margin-bottom: 0;
}

main.project-references p,
main.project-references h1.cursive {
  margin-bottom: 3rem;
}

main.project-references h1.cursive {
  margin-top: 4rem;
}

main.project-references h1.cursive + .btn-back:before {
  margin-bottom: 3rem;
}

.team-list.is-active .team-item,
.team-list.hover .team-item,
.main.projects .project-list.hover .project-item {
  opacity: 0.5;
  transition: opacity .25s;
}

.team-list.hover .team-item:hover,
.team-list.hover .team-item a:hover,
.main.projects .project-list.hover .project-item:hover,
.main.projects .project-list.hover a:hover {
  opacity: 1;
}

.team-list.is-active .team-item.is-active {
  opacity: 1;
  transition: opacity .25s;
}

.team-item.is-active .team-about {
  max-height: 100vh;
  opacity: 1;
}

.menu-item a {
  border-bottom: 0;
}

.menu-item.is-active a {
  font-style: italic;
}


.menu-office-item {
  display: inline-block;
  margin-right: 1.5rem;
  font-family: "corporate-a", Helvetica, Arial, sans-serif;
  font-size: 28px;
  font-style: italic;
  letter-spacing: 0.1em;
}

.menu-office-item.is-active a {
  border: 0;
}

.menu-office-item.is-active a:before {
  width: 8px;
  height: 12px;
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPmNsb3NlX2J0bl9pY29uPC90aXRsZT48ZyBzdHJva2U9IiM0QzRDNEMiIHN0cm9rZS13aWR0aD0iMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIj48cGF0aCBkPSJNNiA2TDIgMk0yIDEwbDQtNCIvPjwvZz48L3N2Zz4=');
  margin-right: 0.5rem;
  display: inline-block;
}

.light .menu-office-item.is-active a:before {
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDggMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPkdyb3VwPC90aXRsZT48ZyBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIj48cGF0aCBkPSJNMiAybDQgNE02IDZsLTQgNCIvPjwvZz48L3N2Zz4=');
}

@media all and (max-width: 45em) {
  .menu-office-item {
    display: block;
  }
}


/* -----  3.3 Footer  ------------------------------------------------ */

.footer ul {
  display: flex;
}

.footer li {
  justify-content: flex-end;
}

.footer {
  padding: 1rem 0;
  font-size: 1em;
}

.footer p {
  margin-bottom: 0;
}

.footer a {
  border-bottom: 0;
}

.footer .menu-item {
  margin-left: 0rem;
}

.footer ul {
  margin: 0;
}

/* -----  3.4 Main Content  ------------------------------------------------- */

/* =====  4 Template-specific Components ==================================== */

/* -----  4.3 Team  --------------------------------------------------------- */



.team-item, .project-item, .project-item-references {
  padding-bottom: 3rem;
}

.project-item-references .project-info {
  margin-top: -2px;
}


@media all and (min-width: 40em) {
  .wrapper {
    padding: 5rem 3rem 0 3rem;
  }

  .navigation .menu {
    padding-left: 3rem;
  }

  .main-slide {
    padding: 6rem 1.5rem 1.5rem 1.5rem;
  }

  .main-slide.contact {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }

  .team-item, .project-item, .project-item-references {
    width: 49.9%;
  }

  .project-image-placeholder {
    padding-bottom: 66.66666%;
  }

  header .hamburger {
    margin-right: 2.1rem;
  }

  header .branding {
    padding: 0;
  }

  .grid.gutter-1 {
    margin-left: -1.5rem; 
    margin-right: -1.5rem;
  }

  .grid.gutter-1 .column {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }


}



@media all and (min-width: 56.1em) {
  .wrapper {
    padding: 3.5rem 3rem 0 3rem;
  }
}

@media all and (min-width: 62.5em) {
  .team-item, .project-item, .project-item-references {
    width: 33.25%;
  }
}


/* Fix for correct scrolling position on team page */

@media all and (max-width: 56em) {
  .main.team {
    margin-top: -5rem;
  }

  .team-item {
    margin-bottom: 0;
    padding-top: 5rem;
    margin-bottom: -2rem;
  }
}




.nav-privacy {
  padding-top: 5rem;
}

.nav-legalnote {
  padding-top: 5rem;
  margin-top: -5rem;
}


/* -----  Swiper  ------------------------------------------------- */

.swiper-container {
  margin: 0;
  padding: 0;
}

.swiper-wrapper {
    height: auto;
}

.swiper-slide {
  height: auto;
}


/* -----  Lazyload  ------------------------------------------------- */

.lazyload {
  filter: blur(10px);
}

.lazyloaded {
  filter: blur(0px);
}